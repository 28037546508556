import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хянах самбараас ямар мэдээллүүдийг авах боломжтой вэ?",
  "description": null,
  "author": "OTC help",
  "category": "dashboard",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хянах самбараас ямар мэдээллүүдийг авах боломжтой вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Хэрэглэгч платформын үндсэн цэснээс `}<strong parentName="li">{`[Dashboard]`}</strong>{` товчийг дарж хянах самбар хэсгээс дараах мэдээллүүдийг авах боломжтой:`}
        <ul parentName="li">
          <li parentName="ul">{`ББЗЗ дэх үнэт цаасны тухайн өдрийн арилжааны ханш, ханшийн өөрчлөлт болон нийт арилжааны дүнгийн мэдээлэл`}</li>
          <li parentName="ul">{`Тухайн өдрийн арилжаагаар хамгийн их ханшийн өсөлттэй болон бууралттай үнэт цаасны жагсаалт`}</li>
          <li parentName="ul">{`ББЗЗ дэх бонд болон хувьцааны сүүлийн 20 арилжааны хэмжээ, үнэ болон нийт арилжааны дүнгийн мэдээлэл`}</li>
          <li parentName="ul">{`Мэдээ, мэдээлэл- Монголын үнэт цаасны арилжаа эрхлэгчдийн холбоо болон платформд оролцогч андеррайтерын нийтэлсэн үнэт цаас болон арилжааны мэдээ, мэдээлэл`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      